import React, { useEffect, useState } from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane,} from 'reactstrap';
import classNames from 'classnames';
import '../../scss/component/tabs.scss';

const EmployeesScreenTabs=(props)=>{

    let [activeTab, setActiveTab] = useState('1');

    let toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return(
        <div className="tabs mt-4" style={{ backgroundColor: '#EAECEF', borderRadius: '5px' }}>
            <div className="tabs__wrap">
                <Nav tabs style={{ backgroundColor: 'white', borderColor: 'orange' }}>
                    <NavItem>
                        <NavLink
                            className={classNames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1') }}>{'Activity'}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2') }}>{'Sprint Report'}</NavLink>
                    </NavItem>
                    
                </Nav>
                <TabContent activeTab={activeTab} style={{ overflow: 'auto' }}>
                    <TabPane tabId="1">
                        <div style={{ maxHeight: 400}}>
                            {props.getDataTable()}
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div style={{ maxHeight: 400 , maxWidth:585}}>
                            {props.getDataTable('empSprintTable')}
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        </div>
    )
    
}

export default EmployeesScreenTabs;