import React, { useEffect, useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import { Button } from "reactstrap";
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import fetchMethodRequest from "../../../config/service";
import DoTextareaField from "../../Form/Fields/DoTextareaField";
import showToasterMessage from "../../UI/ToasterMessage/toasterMessage";
import DoAutoCompleteField from "../../Form/Fields/DoAutoCompleteField";
import moment from 'moment';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Loader from "../../App/Loader";

const FeedbackModal = (props) => {

    const [feedbacksData, setFeedbacksData] = useState();
    const [filterCriteria, setFilterCriteria] = useState({ limit: 20, page: 1, criteria: [] });
    const [activeTab, setActiveTab] = useState('1');
    const [isLoading, setIsLoading] = useState(false);


    const [tableFields, setTableFields] = useState([
        // { textAlign: 'center', width: 20, field: 'Sno', header: 'S.No.' },
        // { textAlign: 'center', width: 40, field: 'created', header: 'Date' },
        { textAlign: 'left', width: 30, field: 'employeeName', header: 'Employee Name' },
        { textAlign: 'left', width: 120, field: 'feedbackNotes', header: 'Feedback' },
    ]);

    let loginRole = JSON.parse(localStorage.getItem('loginCredentials'))

    useEffect(() => {
        if (props.sprintDetail.status === 'Completed') {
            setActiveTab("2");
            getFeedback();
        } else {
            setActiveTab("1");
        }
    }, [props.sprintDetail.status]);


    let {
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
        control,
    } = useForm();

    const feedbakFields = [
        {
            "name": "employee",
            "type": "relateAutoComplete",
            "placeholder": "Select Employee",
            "label": "Employee",
            "width": 50,
            "id": "employee",
            "markReq": false,
            "searchField": "displayName",
            "fieldType": "relateAutoComplete",
            "displayFields": ["name"],
            "controllerName": "Project",
            "searchApi": "projects",
            "isMultiple": false,
        },
        {
            "name": "addFeedback",
            "type": "textarea",
            "placeholder": "Add Feedback",
            "label": "Feedback",
            "width": 110,
            "id": "addFeedback",
        },
    ]

    const getFeedback = () =>{
        let tFilterCriteria = filterCriteria;
        let url;
        tFilterCriteria['criteria'] = [{ key: "sprintId", value: props.sprintDetail._id, type: "eq" }];
        url = `feedbackNotes?filter=${JSON.stringify(tFilterCriteria)}`
        fetchMethodRequest('GET', url)
            .then(async (response) => {
                if (response) {
                    const data = response.feedbacknotes.map((obj, index) => {
                        if (obj._id) {
                            return { ...obj, ...obj._id, Sno: index + 1 };
                        } else {
                            return { ...obj, Sno: index + 1 };
                        }
                    });
                    // pushing the feedback from the sprint closure report
                    data.forEach(employeeData => {
                        let matchingEmployee = props.sprintDetail?.sprintClosureId?.find(employee => employee.employeeId === employeeData.employeeId);

                        if (matchingEmployee && matchingEmployee.description) {
                            if (!Array.isArray(employeeData.feedbackNotes)) {
                                employeeData.feedbackNotes = [];
                            }
                            employeeData.feedbackNotes.push(matchingEmployee.description);
                        }
                    });
                    setFeedbacksData(data)

                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
    }

    const onClickSave = (formData) => {
        const payload = {
            "sprintId": props.sprintDetail._id,
            "sprintName": props.sprintDetail.name,
            "feedbackNotes": formData.addFeedback,
            "projectId": props.sprintDetail.projectId._id,
            "projectName": props.sprintDetail.projectId.name,
            "employeeName": formData.employee.displayName,
            "employeeId": formData.employee._id
        }
        if (payload) {
            return fetchMethodRequest('POST', 'feedbackNotes', payload)
                .then(async (response) => {
                    if (response && response.respCode && response.respCode === 204) {
                        showToasterMessage(response.respMessage, 'success');
                        reset({
                            addFeedback: '', 
                            employee: '',
                        });
                        setActiveTab("2");
                        getFeedback();
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                    }
                }).catch((err) => {
                    return err;
                });

        } else {
            return;
        }

    }

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    function formatDate(dateStr) {
        if (!dateStr) return ''; 
        return moment(dateStr).format('DD-MM-YYYY'); 
    }


    return (
        <div className="card flex justify-content-center">
            <Dialog visible={props.visible} modal header="Feedback" style={{ width: '50rem' }} onHide={() => { props.setVisible(false) }}>
                <Nav tabs>
                    {props.sprintDetail.status === 'Completed' 
                        || (loginRole && loginRole.roleType && (loginRole.roleType === 'Employee')) ? '' :
                        <NavItem>
                            <NavLink
                                className={activeTab === '1' ? "active-nav-class" : ""}
                                onClick={() => { toggleTab('1'); }}
                            >
                                Add Feedback
                            </NavLink>
                        </NavItem>
                    }
                    <NavItem>
                        <NavLink
                            className={activeTab === '2' ? "active-nav-class" : ""}
                            onClick={() => { toggleTab('2'); getFeedback(); }}
                        >
                            View Feedback
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    {props.sprintDetail.status === 'Completed' ? '' :
                        <TabPane tabId="1">
                            <form onSubmit={handleSubmit(onClickSave)} className="mt-3">
                                <div className='row'>
                                    {feedbakFields && feedbakFields.length > 0 && feedbakFields.map((item, index) => (
                                        <div className='col-sm-12 pb-2' key={item.name}>
                                            <Controller
                                                name={item.name}
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        {item.type === 'relateAutoComplete' ? (
                                                            <DoAutoCompleteField
                                                                markReq={item.markReq}
                                                                input={field}
                                                                id={field.id}
                                                                name={field.name}
                                                                field={field}
                                                                filterField={item.filterField}
                                                                filterValue={item.filterValue}
                                                                filterType={item.isNotEq}
                                                                multiple={item.isMultiple}
                                                                fieldState={fieldState}
                                                                errors={errors}
                                                                screen={props.type}
                                                                searchApi={item.searchApi}
                                                                searchField={item.searchField}
                                                                criteria={item.filterCriteria ? item.filterCriteria : null}
                                                                allow={props.allowDuplicates}
                                                                filterFieldType={item.filterFieldType ? item.filterFieldType : null}
                                                                placeholder={item.placeholder}
                                                                label={item.label}
                                                                item={item}
                                                                setValue={setValue}
                                                                getValues={getValues}
                                                                onChange={(e) => { }}
                                                                screensss="feedback"
                                                                projectttName={props.sprintDetail.projectId._id}
                                                            />
                                                        ) : item.type === 'textarea' ? (
                                                            <DoTextareaField
                                                                input={field}
                                                                id={field.id}
                                                                name={field.name}
                                                                field={field}
                                                                fieldState={fieldState}
                                                                errors={errors}
                                                                placeholder={item.placeholder ? item.placeholder : null}
                                                                label={item.label}
                                                            />
                                                        ) : null}
                                                    </>
                                                )}
                                            />
                                        </div>
                                    ))}
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                    <Button type="button" color="primary" onClick={props.closeFeedbackModal} style={{ marginRight: '20px' }}>
                                        {'Close'}
                                    </Button>
                                    <Button type='submit' color="primary" >
                                        {'Save'}
                                    </Button>
                                </div>
                            </form>
                        </TabPane>
                    }
                    <TabPane tabId="2">
                        <DataTable
                            value={feedbacksData}
                            stripedRows
                            showGridlines
                            resizableColumns
                            tableStyle={{ minWidth: '30rem', marginTop: '20px', backgroundColor: 'red' }}
                        >
                            {tableFields && tableFields.length > 0 ?
                                tableFields.map((item, i) => {
                                    return <Column key={item.field + i}
                                        style={{
                                            maxWidth: item.width,
                                        }}
                                        bodyStyle={{
                                            whiteSpace: 'normal',
                                            wordWrap: 'break-word',
                                            textAlign: item.textAlign ? item.textAlign : 'left'
                                        }}
                                        field={item.field}
                                        body={
                                            item.field === 'feedbackNotes' ?
                                                (rowData) => (
                                                    <ul>
                                                        {rowData.feedbackNotes.map((note, index) => (
                                                            <li key={index}>{note}</li>
                                                        ))}
                                                    </ul>
                                                )
                                                : item.field === 'created'
                                                    ? (rowData) => formatDate(rowData.created)
                                                    : null
                                        }
                                        header={item.header}
                                        sortable={true}
                                        headerStyle={{ fontWeight: '520', width: item.width, fontSize: 14, color: 'white', backgroundColor: '#f17038' }}
                                    />
                                })
                                : null}
                        </DataTable> 
                    </TabPane>
                </TabContent>
            </Dialog >
        </div >
    );

}

export default FeedbackModal;